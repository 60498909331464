<template>
  <section>
    <v-row>
      <v-col cols="12">
        <p class="secondary--text mb-0 headline">Ofertas presentadas</p>
        <P class="secondary--text mb-0">{{ itemsGenerales.codigo_proceso }}</P>
        <p class="secondary--text mb-0">{{ itemsGenerales.nombre_proceso }}</p>
        <p class="secondary--text mb-0">
          {{ itemsGenerales.forma_contratacion }}
        </p>

        <p class="mb-0">
           {{ items.data[0].nombre_comercial }}
          <v-icon class="ml-3" @click="show()">mdi-eye</v-icon>
        </p>
        <div class="mb-8">
          <v-rating
            :value="calificacion"
            readonly
            background-color="secondary"
            color="secondary"
            half-increments
          ></v-rating>
        </div>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Especificación"
              outlined
              append-icon="mdi-magnify"
              v-model="especificacion"
              @keyup="getProviders"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              @change="getProviders"
              placeholder="Seleccione"
              outlined
              label="OBS"
              :items="selectObs.data"
              item-text="nombre"
              item-value="id"
              v-model="id_obs"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="items.data"
          v-models:select="por_pagina"
          v-models:pagina="pagina"
          :total_registros="total_registros"
          @paginar="paginar"
          @sendItem="sendItem"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="secondary"
              @click="sendItem(item.id_subproceso, item.id_obs)"
              >mdi-eye
            </v-icon>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <!-- Modal -->
    <AppDialog v-model="value" title="Oferta presentada" :maxWidth="1000">
      <template #content>
        <v-row>
          <v-col cols="12" md="4">
            <v-img
              v-if="providerDetails.documento_logo_proveedor != null"
              max-height="242"
              max-width="242"
              :src="providerDetails.documento_logo_proveedor"
            />
            <v-icon v-else color="primary" size="242px">
              mdi-image-outline
            </v-icon>
          </v-col>
          <v-col cols="12" md="4">
            <p class="font-weight-medium primary--text mb-0">
              Nombre del proveedor:
            </p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ providerDetails.nombre }}
            </p>
            <p class="font-weight-medium primary--text mb-0">Tipo de persona</p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ providerDetails.tipo_contribuyente }}
            </p>
            <p class="font-weight-medium primary--text mb-0">DUI:</p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{
                providerDetails.documentos?.find(
                  (target) => target.id_tipo_documento == 1
                )?.numero_documento || ""
              }}
            </p>
            <p class="font-weight-medium primary--text mb-0">NIT:</p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{
                providerDetails.documentos?.find(
                  (target) => target.id_tipo_documento == 2
                )?.numero_documento || ""
              }}
            </p>
            <p class="font-weight-medium primary--text mb-0">Teléfono:</p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ providerDetails.area }} {{ providerDetails.telefono }}
            </p>
            <p class="font-weight-medium primary--text mb-0">
              Correo electrónico:
            </p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ providerDetails.contactos[0].correo_electronico }}
            </p>
          </v-col>
          <v-col cols="12" md="4">
            <p class="font-weight-medium primary--text mb-0">
              Nombre comercial:
            </p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ providerDetails.nombre_comercial }}
            </p>
            <p class="font-weight-medium primary--text mb-0">Género:</p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ providerDetails.genero }}
            </p>
            <p class="font-weight-medium primary--text mb-0">Sitio web:</p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ providerDetails.sitio_web ? providerDetails.sitio_web : "-" }}
            </p>
            <p class="font-weight-medium primary--text mb-0">
              Clasificación de la empresa:
            </p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ providerDetails.clasificacion_empresa }}
            </p>
            <p class="font-weight-medium primary--text mb-0">Dirección:</p>
            <p class="text-subtitle-1 font-weight-bold primary--text">
              {{ getAddress(providerDetails.direccion) }}
            </p>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <div>
          <v-btn
            color="secondary"
            @click="(value = false), limpiar()"
            class="mr-4"
            outlined
          >
            Cerrar
          </v-btn>
        </div>
      </template>
    </AppDialog>
    <!--FIN  Modal -->
    <v-row class="mt-10">
      <v-col cols="12">
        <v-btn class="mt-5" @click="$router.back()" text color="#404a7a"
          >Volver</v-btn
        >
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapState } from "vuex";
export default {
  components: {
    DataTable,
  },
  data: () => ({
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    id_obs: null,
    calificacion: null,
    selectObs: [],
    value: false,
    idSend: null,
    id_subproceso: null,
    id_proceso_compra: null,
    id_proveedor: null,
    especificacion: null,
    items: [],
    headers: [
      {
        text: "Especificación",
        align: "start",
        sortable: false,
        value: "nombre_agrupacion",
        width: "30%",
      },
      { text: "OBS", value: "nombre_ob", width: "20%" },
      { text: "Cantidad", align: "center", value: "cantidad", width: "20%" },
      { text: "Acciones", align: "center", value: "actions", width: "20%" },
    ],
    providerDetails: [],
  }),
  computed: {
    ...mapState("procesoCompraUACI", ["itemsGenerales"]),
  },

  methods: {
    async show(item) {
      this.value = true; 
      this.id_proveedor = this.$route.params.id_proveedor;
      let response = await this.services.Paac.getProviderDetails(this.id_proveedor); //1
      this.providerDetails = response.data;
    },

    async getRating() {
      //let idProveedor = 4;
      //id = 4
    
      this.id_proveedor = this.$route.params.id_proveedor;
      let response = await this.services.Paac.getRatingOfertaPresentada(this.id_proveedor);
      this.calificacion = Number.parseFloat(response.data.calificacion);
    },

    sendItem(id_subproceso, id_obs) {
      this.id_subproceso = id_subproceso;
      this.id_obs = id_obs;
      // alert("ID Seleccionado: " + this.id_subproceso, +this.id_obs);

      this.$router.push({
        name: "reporteriaComparacion",
        params: { id_subproceso: this.id_subproceso, id_obs: this.id_obs },
      });
      // alert("ID Seleccionado: " + this.id_obs);
    },
    getAddress(data) {
      let address = `${data?.pais}${
        data?.departamento ? ", " + data?.departamento : ""
      }${
        data?.municipio ? ", " + this.getMunicipioName(data?.municipio) : ""
      }, ${data?.detalle_direccion}`;
      return address;
    },
    getMunicipioName(data) {
      let name = data?.split(" ");
      name?.pop();
      return name?.join(" ");
    },
    async getSelectObs() {
      this.id = this.$route.params.id;
      this.selectObs = await this.services.Paac.getObsUaci(this.id); //37
    },

    async getProviders() {
      let params = {
        page: this.pagina,
        per_page: this.por_pagina,
        pagination: true,
        especificacion: this.especificacion,
        id_obs: this.id_obs,
      };
      this.id = this.$route.params.id;
     this.id_proveedor = this.$route.params.id_proveedor;
      this.items = await this.services.Paac.getProviders(this.id_proveedor, this.id, params); //1 y 37, (id_proveedor, id_proceso)
      this.total_registros = this.items.headers.total_rows;
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getProviders();
    },
    limpiar() {
      this.providerDetails.nombre = null;
      this.providerDetails.tipo_contribuyente = null;
      this.providerDetails.area = null;
      this.providerDetails.telefono = null;
      this.providerDetails.contactos[0].correo_electronico = null;
      this.providerDetails.nombre_comercial = null;
      this.providerDetails.genero = null;
      this.providerDetails.sitio_web = null;
      this.providerDetails.clasificacion_empresa = null;
      this.providerDetails.direccion = null;
    },
  },

  created() {
    
    this.getRating();
    this.getSelectObs();
    this.getProviders();
  },
};
</script>

<style lang="scss" scoped></style>
